import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import { fetcherSimple, fetcherFull } from 'utils/api';
import ContentWrapper from 'components/ui/content-wrapper';
import UploadPicture from 'assets/img/logos/gobby-logo-FIN_Stacked-BL-wTag-.png';
import { ResponseWrapper } from 'components/ui/response-wrapper';
import Image from 'components/image';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, IconButton, Progress, Typography } from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';
import CustomQuestionResponse from 'components/custom-question-response';
import PresetQuestionResponse from 'components/preset-question-response';

export default function RespondSurvey() {
    const {surveyNanoid} = useParams();
    const navigate = useNavigate();

    const [previewModeChecked, setPreviewModeChecked] = useState(false);
    const [previewModeActive, setPreviewModeActive] = useState(false);
    const [logoAvailable, setLogoAvailable] = useState(true);
    const [openSurvey, setOpenSurvey] = useState(false);
    // auth session (means preview page from survey builder)
    const { data: previewMode, error: previewModeError } = useSWR(() => [ '/v2/auth/session', 'GET'],  ([path, method]) => fetcherSimple(path, method));
    // At this point we might have a authenticated user (via key), or an anon user
    // This should return Error if not anonymous + not logged in ?
    const {data: auth, error: authError} = useSWR(() => [`/v2/response/anon/${surveyNanoid}`, 'GET'], ([path, method]) => fetcherSimple(path, method));
    // landing page should have done all the auth, must have a respondent_cookie here
    // response survey view:
    const {data: survey, error: surveyError} = useSWR(() => [`/v2/response/survey`, 'GET'], ([path, method]) => fetcherSimple(path, method));
    const {data: status, error: statusError} = useSWR([`/v2/response/status`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const [surveyProgress, setSurveyProgress] = useState(0);
    const [current, setCurrent] = useState(1);
    // Once survey loaded (must have questions),
    // and current is set (starts at 1) -
    // Load existing responses to this question, if any:
    const {data: questionResponse, error: questionResponseError} =
          useSWR(() => [ survey && current && survey.questions[current-1]
            ? '/v2/response/question/' + survey.questions[current-1].nanoid
            : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );
    // Load any preset answers for this question, if any:
    // (the api seems to allow paging + queries but the UX says this is a pick one/many from list)
    const {data: questionPresets, error: questionPresetsError} =
          useSWR(() => [ survey && current && survey.questions[current-1]
                         && (survey.questions[current-1].question_type === 'fixed' || survey.questions[current-1].question_type === 'mixed')
                         ? `/v2/response/question/${survey.questions[current-1].nanoid}/preset`
                         : null, 'GET'], ([path, method]) => fetcherSimple(path, method)
    );

    useEffect(() => {
        setPreviewModeChecked(true);
        if (previewMode) {
            setPreviewModeActive(true);
        }
    }, [previewMode]);

    useEffect(() => {
        if ((status?.is_started === true) && (status?.finished === false) && (status?.survey?.state === 'active')) {
            setOpenSurvey(true);
        }
    }, [status]);

    // sorted questions
    useEffect(() => {
        if (survey) {
            survey.questions.sort((a, b) => {
                return a.position - b.position;
            });
        }
    }, [survey]);

    // progress bar update
    useEffect(() => {
        if (survey && current > 0) {
            const progress = (current - 1) / survey.questions.length * 100;
            const truncatedProgress = Math.trunc(progress);
            setSurveyProgress(truncatedProgress);
        }
    }, [survey, current]);

    // state for current question
    const [questionSaved, setQuestionSaved] = useState(false);
    const [presetOther, setPresetOther] = useState(true);
    useEffect(() => {
        if (questionResponse && (questionResponse.responses.preset.length > 0
                                 || questionResponse.responses.custom.length > 0)) {
            setQuestionSaved(true);
        }
        if (survey) {
            const question = survey.questions[current-1];
            if (questionResponse
                && question.question_type === 'mixed'
                && question.answer_type === 'single'
                // && questionResponse.responses.custom.length === 0
                && questionResponse.responses.preset.length > 0) {
                setPresetOther(false);
            }
        }
    }, [survey, questionResponse,]);

    // Form state(s)?
    const [allValid, setAllValid] = useState(false);

    const nextQuestion = () => {
        if (current === survey.questions.length ) {
            setAnswers(questionResponse.responses);
            handleSurveySubmit();
        } else {
            setAnswers(questionResponse.responses);
            setCurrent(prevCurrent => prevCurrent + 1);
            setQuestionSaved(false);
            setPresetOther(true);
        }
    };
    
    // store / ensure question answers are stored
    const setAnswers = async (responses) => {
        // questionResponse.responses (map to lists of nanoids)
        // set_answers
        // POST /v2/response/question/{question_nanoid} {'custom':[],'preset':[]}
        if (previewModeActive) {
            return;
        }
        const result = await fetcherFull(`/v2/response/question/${survey.questions[current-1].nanoid}`,
                                         'POST', {'preset': responses.preset.map((p) => p.nanoid),
                                                  'custom': responses.custom.map((c) => c.nanoid)});
        if (result.success) {
            console.log('Submitted set_answers');
            mutateAnswers();
        } else {
            console.log('Set Answers failed');
            console.log(result);
        }
    };
    
    // end entire survey
    const handleSurveySubmit = async () => {
        if (previewModeActive) {
            navigate(`/respond/${surveyNanoid}?previewThankYou=1`);
            return;
        }

        // GET /v2/response/finish
        const finish = await fetcherFull('/v2/response/finish', 'GET');
        if (finish.success) {
            console.log('Finished survey');
            navigate(`/respond/${surveyNanoid}`);
        } else {
            console.log('Finish failed');
            console.log(finish);
        }
    };

    const { mutate } = useSWRConfig();

    // update questionResponse with just-submitted answers
    const mutateAnswers = () => {
        // now save the set answers for this respondent/question combo!?
        mutate(() => [ survey && current && survey.questions[current-1]
            ? '/v2/response/question/' + survey.questions[current-1].nanoid
                       : null, 'GET']);
    };

    return (
        <ResponseWrapper>
            <ContentWrapper>
                <>
                {((surveyError) &&
                    <div>
                        There has been an error loading the survey that you requested! Please check with the survey administrator that you have been provided
                        with a valid url for an existing survey. 
                    </div>
                )}
                {((statusError) &&
                    <div>
                        Error loading survey status!
                    </div>
                )}
                {((!previewMode && !openSurvey) &&
                <>
                    <div className="flex flex-col md:max-w-[800px] mx-auto mt-4 bg-white rounded-xl sm:my-6 my-4">
                    <div className="py-4">
                        <Alert color="red" className="mt-1 rounded-xl py-1 pl-5">
                            Survey is Disabled!
                        </Alert>
                        </div>
                    </div>
                </>
                )}
                {(survey && (previewMode || openSurvey) && questionResponse && questionSaved !== undefined &&
                <div className='w-screen p-0 lg:px-20 sm:py-10 md:px-30 sm:px-10'>
                  <div className="bg-white rounded-xl sm:rounded-2xl pb-2 pt-0 border border-gray-500 lg:w-[80%] xl:w-[70%] 2xl:w-[60%] sm:mx-auto m-1">
                      <div className="flex flex-row md:gap-2 md:px-4 p-2 sm:items-center">
                            <div>
                                {( logoAvailable &&
                                <Image alt="survey logo"
                                    src={process.env.REACT_APP_API_SERVER + '/v2/response/logo'}
                                  className="w-16 md:w-28 sm:mr-2 mr-1"
                                    onError={()=>{setLogoAvailable(false)}}
                                />
                                )}
                            </div>
                            <div className="grow">
                                <h1 className="md:text-2xl text-sm mr-2 font-display text-blue-gray-700">
                                    {survey.survey.name || 'Loading Survey Name'}
                                </h1>
                            </div>
                            {(previewModeActive &&
                                <div className='font-display lg:text-md text-sm text-red-700 rounded-full border border-red-900 text-center bg-white px-2 py-1 sm:px-5'>
                                  PREVIEW MODE!
                                </div>
                             )}
                          <div className="shrink-0">
                              <Image
                                  className="lg:w-32 md:w-28 w-16"
                                  src={UploadPicture}
                                  rounded={false}
                                  raised={false}
                                  alt="Image"/>
                          </div>
                      </div>
                      <div className="flex flex-row sm:px-4 px-2 bg-white gap-2 items-center">
                      <Progress value={surveyProgress} 
                                    className='border border-blue-gray-700 border rounded-full bg-white font-body' 
                                    label="&nbsp;"
                                    variant="gradient"
                                    color="blue-gray"
                                    size="lg"
                                     />
                          <IconButton color="blue-gray" type="button" variant="gradient" size="sm"
                                      disabled={current === 1} onClick={(()=>{ setCurrent(prevCurrent => prevCurrent - 1); setQuestionSaved(false)})}>
                              <MaterialIcon name="arrow_back_ios_new" size="sm"/>
                          </IconButton>
                          <IconButton color="blue-gray" type="button" variant="gradient" size="sm" 
                                disabled={(current === survey.questions.length) || !(previewModeActive || questionSaved)} 
                                onClick={nextQuestion}>
                              <MaterialIcon name="arrow_forward_ios" size="sm"/>
                          </IconButton>
                      </div>
                      <div className="sm:px-2 sm:pt-2 p-1">
                          <Card className="bg-gray-200 md:p-2 p-0" shadow={true}>
                              <CardHeader variant="filled" className="bg-white m-0 p-2 border border-gray-500" shadow={false} floated={false}>
                                  <Typography className="font-display text-blue-gray-700 text-xl">
                                      Q{current}.&nbsp;&nbsp;{survey.questions[current-1].text}
                                  </Typography>
                                  <Typography className="font-body mt-2 ml-1 text-blue-gray-700">
                                      {survey.questions[current-1].description || ''}
                                  </Typography>
                              </CardHeader>
                              <CardBody className='border border-gray-700 rounded-xl bg-white p-2 sm:p-4 mt-2'>
                                  <>
                                      {survey.questions[current-1]
                                        && (survey.questions[current-1].question_type === 'fixed'
                                            || survey.questions[current-1].question_type === 'mixed')
                                       && (questionPresets && questionPresets.preset_answers.length > 0) &&
                                        <PresetQuestionResponse
                                            question={survey.questions[current-1]}
                                            responses={questionResponse.responses}
                                            update={mutateAnswers}
                                            setAnswers={setAnswers}
                                            setPresetOther={setPresetOther}
                                            setQuestionSaved={setQuestionSaved}
                                            savedStatus={questionSaved}
                                            presetOther={presetOther}
                                        />
                                      }
                                      {survey.questions[current-1]
                                        && (survey.questions[current-1].question_type === 'freeform'
                                            || survey.questions[current-1].question_type === 'mixed') &&
                                        <CustomQuestionResponse
                                            question={survey.questions[current-1]}
                                            responses={questionResponse.responses}
                                            update={mutateAnswers}
                                            setAnswers={setAnswers}
                                            setPresetOther={setPresetOther}
                                            setQuestionSaved={setQuestionSaved}
                                            savedStatus={questionSaved}
                                            presetOther={presetOther}
                                            previewModeState={previewModeActive}
                                        />
                                       }
                                  </>
                              </CardBody>
                              <CardFooter className='mt-2'>
                                <div className='absolute bottom-2 sm:right-2 right-1'>
                                  <Button type="submit"
                                          className='rounded-full py-1 border border-gray-500 hover:bg-black hover:text-white font-body font-thin text-lg normal-case' 
                                          size="lg" 
                                          color={questionSaved || previewModeActive ? "green" : "gray"}
                                          variant="filled"
                                          disabled={!(questionSaved || previewModeActive) }
                                          // createCustomAnswer
                                          onClick={nextQuestion}
                                  >
                                  {questionSaved && current === survey.questions.length ? 'Submit answer and complete survey': 'Next'}
                                  </Button>
                                </div>
                              </CardFooter>
                          </Card>
                      </div>
                  </div>
                </div>
                 )}
                </>
            </ContentWrapper>
        </ResponseWrapper>
    );
}
