import React, { createRef, useState } from 'react';
import {Accordion, AccordionBody, AccordionHeader, Card, CardBody, CardHeader, Button, Typography} from '@material-tailwind/react';
import dayjs from 'dayjs';
import IconAcc from 'components/accordion-icon';
import { Form, Formik } from 'formik';
import ContentWrapper from 'components/ui/content-wrapper';
import useSWR from 'swr';
import * as Yup from 'yup';
import { fetcherFileUpload, fetcherFull, fetcherSimple } from 'utils/api';
import FormikInputIcon from 'components/form/formik-input-icon';
import { toast } from 'react-toastify';
import FormikInput from 'components/form/formik-input';
import Image from 'components/image';
import UploadPicture from 'assets/img/uploadprofilepic.png';
import MaterialIcon from 'components/material-icon';

export default function Profile() {
    //AccordionControls
    const [open, setOpen] = useState(0);     
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    
    const [profilePicDeletable, setProfilePicDeletable] = useState(true);
    // timestamp value function with dayjs
    const [timestamp, setTimestamp] = useState(dayjs());

    // Refs
    const inputLogoFileRef = createRef(null);

    const {
        data: userData,
        error: UserDataError,
        mutate: userDataMutate,
        isValidating: userDataIsValidating,
    } = useSWR([`/v2/user`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const imageUrl = process.env.REACT_APP_API_SERVER + `/v2/user/picture`;

    const validationSchemaUser = Yup.object().shape({
        name: Yup.string().optional(),
        alt_email: Yup.string().optional(),
        phone: Yup.string().optional(),
        address_1: Yup.string().optional(),
        address_2: Yup.string().optional(),
        postcode: Yup.string().optional(),
        country: Yup.string().optional(),
        organisation: Yup.string().optional(),
        job_role: Yup.string().optional()
    });

    const validationSchemaPassword = Yup.object().shape({
        password: Yup.string().required('New Password is Required'),
        confirm_password: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match").required('Confirm Password is required'),
    });

    const handleLogoUploadClick = () => {
        inputLogoFileRef.current?.click();
    };

    const profilePicUploadHandler = async (event) => {
        const csvSubmitToast = toast.loading('Saving Image...');
        if (!event.target.files) {
            toast.error('No file uploaded!');
            return;
        }
        const selectedFile = event.target.files[0];
        if (!selectedFile.type.includes('image/')) {
            toast.error('Only Image Files are supported. Please ensure that the file you are loading is not excessively large and has the correct extension and name.');
            return;
        }
        let imageResponse = undefined;
        try {
            imageResponse = await fetcherFileUpload(
                `/v2/user/picture`, 'POST', 'picture', selectedFile
            );
        } catch {
            const serverError = imageResponse || 'unknown issue in uploading image. Please try a different size or type.';
            toast.update(csvSubmitToast, {render: `Error uploading Image, ${serverError}.`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        }
        if (imageResponse && imageResponse?.status === 200) {
            toast.update(csvSubmitToast, {render: 'Profile Picture Uploaded', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            setTimestamp(dayjs());
            setProfilePicDeletable(true);
        } else {
            const serverError = imageResponse || 'unknown issue in uploading image. Please try a different size or type.';
            toast.update(csvSubmitToast, {render: `Error uploading Image, ${serverError}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(imageResponse.errors);
        }
    }

    const handleDeleteProfilePic = async () => {
        const deleteResponse = await fetcherSimple(
            `/v2/user/picture`, 'DELETE'
        );
        if (deleteResponse && deleteResponse.deleted) {
            toast.success('Profile Picture Deleted!');
            setProfilePicDeletable(false);
            setTimestamp(dayjs());
        } else if (deleteResponse && deleteResponse.error) {
            toast.error(`Error deleting picture: ${deleteResponse.message}`);
        } else {
            toast.error('Error deleting picture!')
        }
    }

    const handleUserSubmit = async (values) => {
        console.log(values)
        let sanitary_values = {};
        for (const property in values) {
            if ((values[property])) {
                sanitary_values[property] = values[property]
            }
        }
        console.log(sanitary_values)
        const userSubmitToast = toast.loading('Saving User...');
        let response = undefined;
        let serverError = undefined;
        try {
            response = await fetcherFull(`/v2/user`, 'PUT', sanitary_values);
        } catch {
            serverError = response || 'Unknown issue';
        }
        if (response?.success && response?.success === false) {
            toast.update(userSubmitToast, {render: `Error updating User, ${response.error || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(response.error);
        } else if (serverError) {
            toast.update(userSubmitToast, {render: `Error updating User, ${serverError || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        } else {
            toast.update(userSubmitToast, {render: 'User Updated', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            userDataMutate(`/v2/user`);
        }
    }

    const handlePasswordSubmit = async (values) => {
        delete values.confirm_password;
        const userSubmitToast = toast.loading('Saving Password...');
        let response = undefined;
        let serverError = undefined;
        try {
            response = await fetcherFull(`/v2/user/password`, 'PUT', values);
        } catch {
            serverError = response || 'Unknown issue';
        }
        if (!values.password || values.password === '') {
            delete values.password;
        }
        if (response?.success && response?.success === false) {
            toast.update(userSubmitToast, {render: `Error updating Password, ${response.error || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(response.error);
        } else if (serverError) {
            toast.update(userSubmitToast, {render: `Error updating Password, ${serverError || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        } else {
            toast.update(userSubmitToast, {render: 'Password Updated', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            userDataMutate(`/v2/user`);
        }
    }

    return (
        <>
            <ContentWrapper>
            <div className='pt-6 py-2 mb-6  md:max-w-[50%]'>
                <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:pl-6 pl-2 rounded-xl md:rounded-3xl bg-gray-100 border-b-2 lg:border-b-2 border-blue-400'>Profile</h1>
            </div>
            <div className='flex flex-row sm:gap-10 gap-2'>
                <div className='basis-1/2 mt-4'>
                    <Card className='mx-2 mb-4 bg-gray-200'
                            variant='gradient'>
                        <CardHeader className='m-0 mb-2 rounded-none p-4 text-2xl font-display text-gray-700'
                                    color="transparent"
                                    floated={true}
                                    shadow={true}>
                                Details
                        </CardHeader>
                        <CardBody className='flex flex-col px-6 py-2'>
                            <div className='flex flex-row'>
                                <div className='basis-2/3 w-full border border-gray-700 p-2 rounded-2xl bg-white'>
                                    <Typography className='font-display text-blue-700'>Account Holder</Typography>
                                    <Typography className='font-body text-gray-700'>
                                        <MaterialIcon name="person"/> {userData?.name || 'N/A'}<br />
                                        <MaterialIcon name="alternate_email"/> {userData?.alt_email || 'N/A'}<br />
                                        <MaterialIcon name="call"/> {userData?.phone || 'N/A'}<br />
                                    </Typography>
                                </div>
                                <div className='basis-1/3 w-full border border-gray-700 p-2 rounded-2xl bg-white ml-4'>
                                <Image alt="survey logo" 
                                    key={timestamp}
                                    src={imageUrl}
                                    className="mt-6 rounded-lg border border-black"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src=`${UploadPicture}`;
                                    }} 
                                />
                                </div>
                            </div>
                            <div className='w-full border border-gray-700 p-2 rounded-2xl bg-white my-2'>
                                <Typography className='font-display text-blue-700'>Organisation</Typography>
                                <Typography className='font-body text-gray-700'>
                                    <MaterialIcon name="store"/> {userData?.organisation || 'N/A'}<br />
                                    <MaterialIcon name="badge"/> {userData?.job_role || 'N/A'}<br />
                                </Typography>
                            </div>
                            <div className='w-full border border-gray-700 p-2 rounded-2xl bg-white my-2'>
                                <Typography className='font-display text-blue-700'>Address</Typography>
                                <Typography className='font-body text-gray-700'>
                                    <MaterialIcon name="contact_mail"/> {userData?.address_1 || 'N/A'}<br />
                                    <MaterialIcon name="contact_mail"/> {userData?.address_2 || 'N/A'}<br />
                                    <MaterialIcon name="contact_mail"/> {userData?.postcode || 'N/A'}<br />
                                    <MaterialIcon name="contact_mail"/> {userData?.country || 'N/A'}<br />
                                </Typography>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className='basis-1/3 rounded-xl min-h-[100px] mt-4'>
                    <Accordion open={open === 2} icon={<IconAcc id={2} open={open} />}>
                        <AccordionHeader className="bg-white px-2 py-1 text-lg rounded-lg hover:border-b-blue-300 border border-gray-400 
                                                    border-b-2 border-b-gray-500 hover:bg-gray-300 "
                            onClick={() => handleOpen(2)}>
                            Update Details
                        </AccordionHeader>
                        <AccordionBody className="py-1 rounded-lg mt-1 border border-gray-700 border-b-2 bg-white border-b-gray-500 px-2 pb-4">
                        <></>
                        {(userData &&
                            <Formik onSubmit={handleUserSubmit} validationSchema={validationSchemaUser} 
                                initialValues={{
                                    name: userData?.name || '',
                                    alt_email: userData?.alt_email || '',
                                    phone: userData?.phone || '',
                                    address_1: userData?.address_1 || '',
                                    address_2: userData?.address_2 || '',
                                    postcode: userData?.postcode || '',
                                    country: userData?.country || '',
                                    organisation: userData?.organisation || '',
                                    job_role: userData?.job_role || ''
                                }}
                                enableReinitialize>
                                {({isValid, dirty}) => (
                                <Form>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg">
                                        <FormikInputIcon name="name" type="text" color="gray" className='font-body bg-white'
                                                        label="Display Name" iconName="person" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-1">
                                        <FormikInputIcon name="alt_email" type="email" color="gray" className='font-body bg-white'
                                                        label="Alternative Email Address" iconName="alternate_email" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-1">
                                        <FormikInputIcon name="phone" type="text" color="gray" className='font-body bg-white'
                                                        label="Contact Phone Number" iconName="call" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-6">
                                        <FormikInputIcon name="address_1" type="text" color="gray" className='font-body bg-white'
                                                        label="First Line of Address" iconName="contact_mail" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-1">
                                        <FormikInputIcon name="address_2" type="text" color="gray" className='font-body bg-white'
                                                        label="Second Line of Address" iconName="contact_mail" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-1">
                                        <FormikInputIcon name="postcode" type="text" color="gray" className='font-body bg-white'
                                                        label="Postcode/Zip Code" iconName="contact_mail" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-1">
                                        <FormikInputIcon name="country" type="text" color="gray" className='font-body bg-white'
                                                        label="Country" iconName="contact_mail" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-6">
                                        <FormikInputIcon name="organisation" type="text" color="gray" className='font-body bg-white'
                                                        label="Organisation/Company Name" iconName="store" autoComplete="off" />
                                    </div>
                                    <div className="ml-2 my-1 font-body bg-white rounded-lg mt-1">
                                        <FormikInputIcon name="job_role" type="text" color="gray" className='font-body bg-white'
                                                        label="Role in Organisation" iconName="badge" autoComplete="off" />
                                    </div>
                                    <div className='flex justify-end'>
                                    <Button className="rounded-full py-1 px-4 font-body 
                                            font-thin normal-case mt-4 
                                            hover:text-white border border-gray-700 hover:bg-black text-lg"
                                            type="submit"
                                            disabled={!(dirty) || !(isValid)} variant="filled" color="white" size="lg">
                                            Update
                                    </Button>
                                    </div>
                                    <div className='font-body text-gray-800 mt-4'>
                                        <div className="mt-4 md:flex md:flex-row md:items-top bg-white
                                                    md:border border-gray-700 rounded-xl">
                                            <div className='md:p-4 p-2'>
                                                <Button variant="gradient" type="button" color="white"
                                                    onClick={() => handleLogoUploadClick()}
                                                    className='rounded-lg px-2 py-2 mx-auto 
                                                    mt-1 border border-gray-500
                                                    hover:border-1 hover:border-blue-500 hover:text-blue-500 
                                                    font-body normal-case font-thin' size="md">
                                                Upload Image
                                                </Button>
                                                {( profilePicDeletable &&
                                                <Button variant="gradient" type="button"
                                                    onClick={() => handleDeleteProfilePic()}
                                                    color="white"
                                                    className='rounded-full px-6 py-2 mr-2 
                                                    mt-1 border border-gray-500
                                                    hover:border-1 hover:border-blue-500 hover:text-blue-500 
                                                    font-body normal-case font-thin' size="lg">
                                                    Delete Uploaded Logo
                                                </Button>
                                                )}
                                                <input onChange={profilePicUploadHandler}
                                                    ref={inputLogoFileRef}
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                />
                                                <br/><br/><br/><strong>Currently WIP API, <br />not working/hooked up</strong>
                                            </div>
                                            <div className='p-2'>
                                                <Image alt="Profile picture" 
                                                    key={timestamp}
                                                    src={imageUrl}
                                                    className="rounded-xl"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src=`${UploadPicture}`;
                                                        setProfilePicDeletable(false);
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                            )}
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 1} icon={<IconAcc id={1} open={open} />}>
                        <AccordionHeader className="bg-white px-2 py-1 text-lg rounded-lg hover:border-b-blue-300 border border-gray-400 
                                                    border-b-2 border-b-gray-500 hover:bg-gray-300 mt-4"
                            onClick={() => handleOpen(1)}>
                            Update Password
                        </AccordionHeader>
                        <AccordionBody className="py-1 rounded-lg mt-1 border border-gray-700 border-b-2 bg-white border-b-gray-500 px-2 pb-4">
                            <></>
                            {(userData &&
                            <Formik onSubmit={handlePasswordSubmit} validationSchema={validationSchemaPassword} 
                                initialValues={{
                                    password: '',
                                    confirm_password: '',
                                }}
                            >
                            {({isValid, dirty}) => (
                                <Form>
                                    <div className="my-1 rounded-xl p-0 bg-white font-body break-after-column">
                                        <div className="pb-4">
                                            <FormikInputIcon name="password" type="password" color="gray" className='font-body'
                                                label="New Password" iconName="lock" autoComplete="new-password" />
                                        </div>
                                        <div className="pb-4">
                                            <FormikInput name="confirm_password" type="password" color="gray" className='font-body'
                                                label="Confirm New Password" autoComplete="new-password" />
                                        </div>
                                        <div className='flex flex-row-reverse'>
                                        <Button className="rounded-full py-1 px-4 font-body 
                                                font-thin normal-case 
                                                hover:text-white border border-gray-700 hover:bg-black text-lg"
                                                type="submit"
                                                disabled={!(dirty) || !(isValid)} variant="filled" color="white" size="lg">
                                                Save
                                        </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                            </Formik>
                            )}
                        </AccordionBody>
                    </Accordion>
                </div>
            </div>
            </ContentWrapper>
        </>
    );
}
