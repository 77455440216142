import ContentWrapper from 'components/ui/content-wrapper';
import {Accordion, AccordionBody, AccordionHeader, Card, CardBody, CardFooter, Checkbox, 
    Button, Alert, Typography, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';

export default function Dashboard() {
    //AccordionControls
    // const [open, setOpen] = useState(0);     
    // const handleOpen = (value) => setOpen(open === value ? 0 : value);

    return (
        <>
            <ContentWrapper>
                <div className='pt-6 py-2 mb-6 md:max-w-[50%]'>
                <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:pl-6 pl-2 rounded-xl md:rounded-3xl bg-gray-100 border-b-2 lg:border-b-2 border-blue-400'>Dashboard</h1>
                </div>
                <div className="mb-10 p-2 text-start border border-gray-700 rounded-xl">
                    Welcome back [USERNAME]
                    <div className='flex flex-row gap-2'>
                        <div className='basis-3/4 border border-blue-700 rounded-xl min-h-[100px] mt-4'></div>
                        <div className='basis-1/4 border border-blue-700 rounded-xl min-h-[100px] mt-4'></div>
                    </div> 
                </div>
                <div className="">
                </div>
            </ContentWrapper>
        </>
    );
}
